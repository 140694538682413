import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { PDFDocument } from 'pdf-lib';
import downloadFile from 'js-file-download';
import axios from 'axios';
import JSZip from 'jszip';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

import ResultsTable_Table from './ResultsTable_Table';
import { Text, Button, ErrorToast } from 'components';
import SendResultsModal from 'features/SendResultsModal/SendResultsModal';
import DownloadPdfModal from './components/DownloadPdfModal';
import SearchBar from './components/SearchBar';

import resultApi from 'api/result/resultApi';

import {
  ResultPdfUploadModal,
  LabResultsTableFilter,
  PatientBiomarkerOverview,
  DeleteResultsModal,
} from 'features';

import './resultsTable.scss';

const ResultsTable = ({
  fetchResults,
  onRowClick,
  results,
  userInfo,
  isSearching,
  paginationInfo,
  setSearching,
  setResults,
  setPaginationInfo,
  hasPatientColumn = true,
  changerVar,
  filtered,
  isPatientApp,
  hideUpload,
  showActions = true
}) => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  const initailActiveList = urlSearchParams.get('add-t-filter') || 'result';

  const defaultSearchParams = {
    limit: Number(urlSearchParams.get(`lr-limit`)) || 20,
    page: Number(urlSearchParams.get(`lr-page`)) || 1,
    totalPages: Number(urlSearchParams.get(`lr-total-pages`)) || 0,
    searchTerm: urlSearchParams.get(`lr-search-term`) || '',
    fromDate:
      (urlSearchParams.get(`lr-from-date`) &&
        new Date(urlSearchParams.get(`lr-from-date`))) ||
      '',
    toDate:
      (urlSearchParams.get(`lr-to-date`) &&
        new Date(urlSearchParams.get(`lr-to-date`))) ||
      '',
    labId: urlSearchParams.get(`lr-lab-id`) || '',
    clinicId: urlSearchParams.get(`lr-clinic-id`) || '',
    testStatus: urlSearchParams.get('lr-test-status') || 'all',
    clients: urlSearchParams.get(`lr-clients`)?.split(',') || [],
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const filterDropdownRef = useRef();

  const clientInformation = useSelector(
    (state) => state.general.clientInformation
  );
  const labList = useSelector((state) => state.general.labClinics);
  const { customUrlClient } = useSelector((state) => state.general);

  const [isModalToggled, toggleModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedTestStatus, setSelectedTestStatus] = useState('all');
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [pdfModalToggled, togglePdfModals] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isShowFilters, setShowFilters] = useState(false);
  const [showDeleteResultsModal, setShowDeleteResultsModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedClinics, setSelectedClinics] = useState([
    clientInformation.clientId,
  ]);
  const [toggleUploadReport, setToggleuploadReport] = useState(false);
  const [activeList, setActiveList] = useState(initailActiveList);
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = useCallback(
    debounce((params) => {
      if (!fetchResults) return;
      fetchResults(params);
    }, 500),
    [changerVar]
  );

  useEffect(() => {
    fetchClients();
  }, [labList]);

  useEffect(() => {
    // if (firstRender) return setFirstRender(false);
    handleSearch(searchParams);
  }, [searchParams, changerVar]);

  useEffect(() => {
    getFilteredData();
  }, [results, activeList]);

  useEffect(() => {
    setSelectedRows([]);
  }, [activeList]);

  const getFilteredData = () => {
    if (!filtered) return setFilteredData(results);

    const filteredRes = results?.filter((result) => {
      if (activeList === 'result') {
        return result?.type === activeList || !result?.type;
      }
      return result?.type === activeList;
    });

    setFilteredData(filteredRes);
  };

  const fetchClients = async () => {
    if (clientInformation.type !== 'lab') return;

    const clinicNames = labList.map((clinics) => ({
      text: clinics.name,
      key: clinics.clientId,
      value: clinics.clientId,
    }));

    clinicNames.unshift({
      text: <Text id="results_inbox.all_clinics">All clinics</Text>,
      key: 'all',
      value: 'all',
    });

    setClients(clinicNames);
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !filterDropdownRef.current ||
        filterDropdownRef.current.contains(event.target)
      ) {
        return;
      }
      setShowFilters(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [filterDropdownRef, setShowFilters]);

  const updateSearchParams = (params) => {
    let selectedClinicz = [...params.clients];

    if (params?.clients?.length === 0)
      selectedClinicz = [clientInformation?.clientId];
    // selectedClinicz = clinicOptions.map((clinic) => clinic.value);
    const updatedSearchParams = {
      ...params,
      page: 0,
      clients: selectedClinicz,
    };
    setSearchParams(updatedSearchParams);
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;

    if (JSON.stringify(searchParams) !== JSON.stringify(updatedSearchParams)) {
      updatedSearchParams.limit &&
        searchParams.set(`lr-limit`, updatedSearchParams.limit);
      updatedSearchParams.page &&
        searchParams.set(`lr-page`, updatedSearchParams.page);
      updatedSearchParams.totalPages &&
        searchParams.set(`lr-total-pages`, updatedSearchParams.totalPages);
      searchParams.set(`lr-search-term`, updatedSearchParams.searchTerm);
      if (params.fromDate) {
        searchParams.set(`lr-from-date`, moment(params.fromDate).format('LL'));
      } else {
        searchParams.delete(`lr-from-date`);
      }
      if (params.toDate) {
        searchParams.set(`lr-to-date`, moment(params.toDate).format('LL'));
      } else {
        searchParams.delete(`lr-to-date`);
      }
      updatedSearchParams.labId &&
        searchParams.set(`lr-lab-id`, updatedSearchParams.labId);
      updatedSearchParams.clinicId &&
        searchParams.set(`lr-clinic-id`, updatedSearchParams.clinicId);
      updatedSearchParams.testStatus &&
        searchParams.set(`lr-test-status`, updatedSearchParams.testStatus);
      const filteredClients = updatedSearchParams?.clients?.filter(
        (client) => client !== clientInformation?.clientId
      );
      filteredClients?.length > 0
        ? searchParams.set(`lr-clients`, filteredClients)
        : searchParams.delete(`lr-clients`);
      window.history.replaceState({ path: url.toString() }, '', url.toString());
    }
    console.log(updatedSearchParams, 'updateSearchParams');
  };

  const onSearchChange = (search) => {
    setSearchValue(search);
    updateSearchParams({ ...searchParams, searchTerm: search });
  };

  const onClickMoreData = async () => {
    setSearching(true);

    const updatedSearchParams = {
      ...searchParams,
      page: paginationInfo.page + 1,
    };

    if (clientInformation.type === 'lab' || !!customUrlClient) updatedSearchParams.labId = clientInformation.clientId;

    const moreResultsReponseData = await resultApi.getResults(
      updatedSearchParams
    );
    setResults([...results, ...moreResultsReponseData.results]);
    const { page, totalPages, limit } = moreResultsReponseData;
    setPaginationInfo({ page, totalPages, limit });
    setSearching(false);
  };

  const onRowSelect = (e, id, result) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedRows[id]) delete selectedRows[id];
    else
      selectedRows[id] = {
        patient: { fullName: result.patient?.fullName },
        testProfile: result.display,
        hasPdf: Boolean(result.pdf.length),
        pdf: result.pdf,
      };

    setSelectedRows({ ...selectedRows });
  };

  // const onSelectClient = async ({ value }) => {
  //   setSelectedClient(value);
  //   const updatedSearchParams = { ...searchParams, clinicId: value, page: 0 };
  //   updateSearchParams(updatedSearchParams);
  // };

  const onSelectStatus = async ({ value }) => {
    setSelectedTestStatus(value);
    const updatedSearchParams = { ...searchParams, testStatus: value, page: 0 };
    updateSearchParams(updatedSearchParams);
  };

  const markAsRead = async (isRead) => {
    setIsDownloading(true);
    await resultApi.markResultAsRead(Object.keys(selectedRows), isRead);
    setIsDownloading(false);
  };

  const selectAllRows = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let rows;
    if (Object.keys(selectedRows)?.length === filteredData?.length) {
      setSelectedRows({});
      rows = {};
    } else {
      rows = {};
      filteredData.forEach((element) => {
        rows[element.id] = element;
      });
      setSelectedRows(rows);
    }
  };

  const downloadDocs = async (type) => {
    try {
      setIsDownloading(true);
      togglePdfModals(false);
      const urls = Object.keys(selectedRows)
        .map((docId) => selectedRows[docId])
        .filter(function (el) {
          return el?.pdf?.length > 0;
        });

      if (urls?.length === 0) {
        setIsDownloading(false);
        return toast.warning(
          <ErrorToast
            message={
              <Text id="patient_overview.no_lab_reports">
                Selected results don't have any lab reports
              </Text>
            }
          />
        );
      }

      const urlsArray = urls.map((url) => url.pdf[0].file);
      const signedFiles = await resultApi.getBulkSignedFile(urlsArray);
      const downloadedPdf = signedFiles.map((url, index) => {
        return {
          holderName: urls[index]?.patient?.fullName,
          pdfPath: url,
        };
      });
      if (type === 'individual') await downloadIndividually(downloadedPdf);
      else await mergeAllPDFs(downloadedPdf);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      toast.error(<ErrorToast error={error} />);
    }
  };

  const mergeAllPDFs = async (urls) => {
    const pdfDoc = await PDFDocument.create();
    const numDocs = urls?.length;

    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i].pdfPath).then((res) =>
        res.arrayBuffer()
      );
      const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
      const docLength = donorPdfDoc.getPageCount();
      for (var k = 0; k < docLength; k++) {
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
        pdfDoc.addPage(donorPage);
      }
    }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    axios
      .get(pdfDataUri, {
        responseType: 'blob',
      })
      .then((res) => {
        downloadFile(
          res.data,
          `${moment().format('DD-MM-YYYY HH:mm')} - documents.pdf`
        );
      });
  };

  const downloadIndividually = async (urls) => {
    const pdfZip = new JSZip();
    const numDocs = urls?.length;

    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i].pdfPath).then((res) =>
        res.blob()
      );

      let name = `${urls[i].holderName}.pdf`;
      if (pdfZip.file(name)) name = name.replace('.pdf', ` (${i}).pdf`);
      pdfZip.file(name, donorPdfBytes, { binary: true });
    }

    pdfZip.generateAsync({ type: 'blob' }).then(function (content) {
      downloadFile(
        content,
        `${moment().format('DD-MM-YYYY HH:mm')} - documents.zip`
      );
    });
  };

  const onDeleteResults = async (ids) => {
    try {
      setDeleteLoading(true);
      await resultApi.deleteManyResults(ids);
      fetchResults && (await fetchResults());
      setSelectedRows({});
      setShowDeleteResultsModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="results-table">
      <DeleteResultsModal
        selectedRows={selectedRows}
        onDeleteResults={onDeleteResults}
        showModal={showDeleteResultsModal}
        setShowModal={setShowDeleteResultsModal}
        deleteLoading={deleteLoading}
      />
      {filtered && !!results && (
        <LabResultsTableFilter
          setActiveList={setActiveList}
          activeList={activeList}
          results={results}
        />
      )}

      {activeList === 'overview' ? (
        <PatientBiomarkerOverview
          patientId={changerVar.fhirId || changerVar.id}
          results={results}
        />
      ) : (
        <>
          <SearchBar
            hideUpload={hideUpload}
            isPatientApp={isPatientApp}
            isDownloading={isDownloading}
            toggleModal={toggleModal}
            togglePdfModals={togglePdfModals}
            selectedRows={selectedRows}
            setShowDeleteResultsModal={setShowDeleteResultsModal}
            setToggleuploadReport={setToggleuploadReport}
            filterDropdownRef={filterDropdownRef}
            isShowFilters={isShowFilters}
            updateSearchParams={updateSearchParams}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            clinicOptions={clients}
            selectedClinics={selectedClinics}
            setSelectedClinics={setSelectedClinics}
            isSearching={isSearching}
            isMobile={isMobile}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            setShowFilters={setShowFilters}
            markAsRead={markAsRead}
            showActions={showActions}
          />
          <ResultsTable_Table
            // clients={clients}
            // selectedClient={selectedClient}
            // onSelectClient={onSelectClient}
            results={results}
            filteredData={filteredData}
            searchParams={searchParams}
            selectedRows={selectedRows}
            isMobile={isMobile}
            hasPatientColumn={hasPatientColumn}
            clientInformation={clientInformation}
            onRowSelect={onRowSelect}
            onRowClick={onRowClick}
            customUrlClient={customUrlClient}
            selectedTestStatus={selectedTestStatus}
            selectAllRows={selectAllRows}
            onSelectStatus={onSelectStatus}
          />
          {results?.length > 0 &&
            paginationInfo.page &&
            paginationInfo.page < paginationInfo.totalPages && (
              <Button
                icon
                onClick={() => onClickMoreData()}
                color="blue"
                style={{ width: '200px' }}
                isLoading={isSearching}
                disabled={isSearching}
              >
                <Text color="white" size="small" bold>
                  Load more results
                </Text>
              </Button>
            )}
          {isModalToggled && (
            <SendResultsModal
              results={selectedRows}
              toggleModal={toggleModal}
              isModalToggled={isModalToggled}
              fetchResults={fetchResults}
            />
          )}
          {toggleUploadReport && (
            <ResultPdfUploadModal
              fetchResults={handleSearch}
              toggleModal={setToggleuploadReport}
              isModalToggled={toggleUploadReport}
              patientDetails={changerVar}
              isPatientApp={isPatientApp}
              selectedTab={activeList}
            />
          )}
          {pdfModalToggled && (
            <DownloadPdfModal
              pdfModalToggled={pdfModalToggled}
              togglePdfModals={togglePdfModals}
              isDownloading={isDownloading}
              downloadDocs={downloadDocs}
            />
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(ResultsTable);
