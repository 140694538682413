import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import jwt from 'jwt-decode'; // import dependency
import cx from 'classnames';
import { Divider } from 'semantic-ui-react';
import moment from 'moment';

import {
  Input,
  Button,
  Modal,
  ErrorToast,
  SuccessToast,
  Text,
} from 'components';
import IdAuthService from 'api/identity/authentication';
import IdUserService from 'api/identity/user';
import ClientService from 'api/client/client';
// import ClientService from 'api/clients';
import PasswordReset from '../passwordReset/PasswordReset';
import ActivateUser from '../activateUser/ActivateUser';
import { createUserStorage, setClientStorage } from 'auth/authentication';

import backgroundGraphic from 'assets/graphics/e-lab-graphic.svg';
import RiverrLogo from 'assets/images/riverr-logo-long.png';
import EfLogo from 'assets/images/eurofins-logo.png';
import HeartHandIcon from 'assets/new/heartHand';
import ProfileIcon from 'assets/new/profileOutline';
import blueCheckCircleSvg from 'assets/new/blueCheckCircle.svg';

import { setUser, setClientInformation } from 'redux/generalState.actions';

import history from '../../history';
import userApi from 'api/identity/user';
import patientApi from '../../api/patient/patientApi';

import './login.scss';

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const clientInformation = useSelector(
    (state) => state.general.clientInformation
  );
  const clientBranding = useSelector((state) => state.general.clientBranding);
  const customUrlClient = useSelector((state) => state.general.customUrlClient);

  const PATIENT_DOMAINS = [
    'riverr.health',
    'dev.riverr.health',
    'www.riverr.health',
  ];
  const CLINIC_DOMAINS = ['app.riverr.ai', 'dev.app.riverr.ai'];

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isModalToggled, setIsModalToggled] = useState('');
  const [isResetting, setIsResetting] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginType, setLoginType] = useState('provider');
  const [logo, setLogo] = useState(null);
  const [disableEmail, setDisableEmail] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const domain = window.location.hostname;
    console.log(domain);
    if (CLINIC_DOMAINS.includes(domain)) setLoginType('provider');
    if (PATIENT_DOMAINS.includes(domain)) setLoginType('patient');
    if (domain === 'localhost') setLoginType('local');
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (props.location?.state?.email) setEmail(props.location.state.email);
    if (params.get('email')) {
      setEmail(decodeURI(params.get('email')));
    }
    if (params.get('external')) {
      setDisableEmail(true);
    }
    const type = localStorage.getItem('LOGIN_TYPE');

    if (params.get('type') === 'p') setLoginType('patient');
    if (type && !params.get('type')) setLoginType(type);
  }, []);

  useEffect(() => {
    if (customUrlClient === 'sIdzwYsvwehphIHNG2ce') setLogo(EfLogo);
  }, [customUrlClient]);

  useEffect(() => {
    if (!isInitialRender) localStorage.setItem('LOGIN_TYPE', loginType);
    setIsInitialRender(false);
  }, [loginType]);

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      // const rootId = localStorage.getItem('ROOT_ID');
      // await AuthService.onForgotPassword(resetEmail, rootId);
      await IdAuthService.onForgotPassword(resetEmail);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
      setIsModalToggled(false);
      setResetEmail('');
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }

    setIsResetting(false);
  };

  // const fetchClientDetails = async (clientId) => {
  //   try {
  //     const { data } = await ClientService.getClient(clientId);
  //     dispatch(setClientInformation(data));
  //   } catch (error) {
  //     console.log('fetchClientDetails', error);
  //   }
  // };

  const login = async () => {
    setIsLoggingIn(true);
    try {
      const authResp = await IdAuthService.onSignInUser({
        my_id: { password, username: email },
      });
      if (!authResp) {
        setIsLoggingIn(false);
        return toast.error(
          <ErrorToast message="Login failed, you have entered an invalid username or password." />
        );
      }

      const parsedLocalToken = jwt(authResp.data.access_token);
      const parsedRefreshToken = jwt(authResp.data.id_token);

      const localToken = {
        token: authResp.data.access_token,
        expires: parsedLocalToken.StandardClaims.exp,
        // expires: moment.utc().add(5, 'seconds'),
      };
      const refreshToken = {
        token: authResp.data.id_token,
        expires: parsedRefreshToken.StandardClaims.exp,
        // expires: moment.utc().add(10, 'seconds'),
      };
      localStorage.setItem('AUTH_TOKEN', JSON.stringify(localToken));
      localStorage.setItem('USER_ID', authResp.data.user_id);
      localStorage.setItem('REFRESH_TOKEN', JSON.stringify(refreshToken));
      const {
        data: { user },
      } = await IdUserService.getCurrentUser();
      if (user.metadata?.role === 'patient' && !user?.metadata?.fhirId) {
        const patient =
          await patientApi.createPatientByIdentityUserIfNotExist();
        if (patient?.fhirId) {
          const fhirId = patient.fhirId;
          user.metadata.fhirId = fhirId;
          await userApi.updateMetadata({ fhirId });
        }
      }
      dispatch(setUser(user));
      await createUserStorage(user);
      if (user?.metadata?.role === 'patient') return history.push('/p');
      const {
        data: { client },
      } = await ClientService.getCurrentClient();
      dispatch(setClientInformation(client));
      setClientStorage(client);
      history.push('/results');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const toggleForgotPassword = () => {
    setResetEmail(email);
    setIsModalToggled(true);
  };
  return (
    <div className="login">
      {window.location.hostname.split('.')[0] !== 'elab' && (
        <img className="login__bckg" src={backgroundGraphic} alt="bkcg" />
      )}

      <div className="login__card">
        <img
          className="login__card__logo"
          alt="logo"
          src={logo || RiverrLogo}
        />
        <div className="login__card__content">
          <Switch>
            <Route
              path="/password-reset"
              exact
              component={() => (
                <PasswordReset clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/reset-password/get"
              exact
              component={() => (
                <PasswordReset clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/user-activate"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/activation/get"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/activate"
              exact
              component={() => (
                <ActivateUser clientInformation={clientInformation} />
              )}
            />
            <Route
              path="/"
              exact
              render={() => (
                <>
                  {loginType === 'local' && (
                    <div className="login__types-wrapper">
                      <div
                        className={cx('login__type-wrapper', {
                          'login__type-wrapper--selected':
                            loginType === 'local' || loginType === 'provider',
                        })}
                        onClick={() => setLoginType('provider')}
                      >
                        <Text
                          id="login.provider"
                          color={
                            loginType === 'local' || loginType === 'provider'
                              ? 'white'
                              : '#808080'
                          }
                        >
                          Provider
                        </Text>
                        <HeartHandIcon
                          color={
                            loginType === 'local' || loginType === 'provider'
                              ? 'white'
                              : '#808080'
                          }
                          className="login__type-wrapper__icon"
                        />
                        {loginType === 'provider' && (
                          <img
                            src={blueCheckCircleSvg}
                            alt="check"
                            className="login__type-wrapper__check"
                          />
                        )}
                      </div>
                      <div
                        className={cx('login__type-wrapper', {
                          'login__type-wrapper--selected':
                            loginType === 'patient',
                        })}
                        onClick={() => setLoginType('patient')}
                      >
                        <Text
                          color={loginType === 'patient' ? 'white' : '#808080'}
                        >
                          Patient
                        </Text>
                        <ProfileIcon
                          color={loginType === 'patient' ? 'white' : '#808080'}
                          className="login__type-wrapper__icon"
                        />
                        {loginType === 'patient' && (
                          <img
                            src={blueCheckCircleSvg}
                            alt="check"
                            className="login__type-wrapper__check"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* <div className="login__card__header">eLab Portal</div> */}
                  <Input
                    border
                    label="Email"
                    className="login__card__input"
                    placeholder="mail@domain.com"
                    size="fullWidth"
                    disabled={disableEmail}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                  />
                  <Input
                    border
                    label="Password"
                    className="login__card__input"
                    placeholder="**********"
                    size="fullWidth"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoComplete="password"
                  />
                  <Button
                    className="login__card__button"
                    onClick={() => login()}
                    bold
                    color="blue"
                    isLoading={isLoggingIn}
                    disabled={isLoggingIn}
                  >
                    Login
                  </Button>
                  {loginType === 'patient' && (
                    <div className="mt-3">
                      <Divider horizontal>Or</Divider>
                      <Button
                        className="mt-1"
                        size="fullWidth"
                        onClick={() =>
                          history.push({
                            pathname: '/p/create-account-v1',
                            search: props.location.search,
                          })
                        }
                        bold
                        color="blue"
                        inverted
                      >
                        Create Account
                      </Button>
                    </div>
                  )}
                  <div className="login__card__forgot-wrapper">
                    <Button
                      className="login__card__forgot"
                      size="medium"
                      onClick={() => toggleForgotPassword()}
                      inverted
                      color="white"
                    >
                      <span
                        className="login__card__forgot__text"
                        style={{
                          color: clientBranding?.color || '#5076ff',
                        }}
                      >
                        Forgot password?
                      </span>
                    </Button>
                  </div>
                </>
              )}
            />
          </Switch>
        </div>
      </div>
      {/* <div className="login__image">
        <img
          src={background + '.jpg'}
          alt="background"
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && (
          <img
            src={background + '-mini.jpg'}
            alt="background"
            className="login__image--mini"
          />
        )}
      </div> */}
      {isModalToggled && (
        <Modal
          closeIcon
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="You forgot your password? Enter your email below to receive a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color="blue"
              isLoading={isResetting}
              textId="forget_pw.reset_password"
            >
              Reset Password
            </Button>
          }
        >
          <Input
            border
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
